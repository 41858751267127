.reportHeader {
  padding: 4rem 0 4rem 0;

  &__title {
    font-size: 48px;
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  &__author {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 1.5rem;
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }

  &__authorImg {
    margin-right: 1rem;
  }

  &__authorInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0 0 0;
  }

  &__authorInfoText {
    flex-direction: column;
  }

  &__authorDate {
    margin-top: -0.5em;
  }

  &__coverImgTitle {
    margin-top: 1.5rem;
  }

  &__newsHeaderTop {
    padding: 0 0 2rem 0;
  }

  &__textFlex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 2.5rem;
  }

  &__contact {
    display: flex;
    flex-direction: column;
  }
}
