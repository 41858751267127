@import "./variables";
@media print {
  .brak-after {
    page-break-after: always;
  }
}

#main_nav + * {
  margin-top: 0px;
  @media (max-width: 767px) {
    margin-top: 00px;
  }
}

.h2-input-group {
  margin-bottom: 12px;
  .h2-input-group_title {
    font-size: rem(20);
    color: #fff;
    margin-bottom: 10px;
  }

  &.white-block {
    background-color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    .h2-input-group_title {
      font-size: rem(18);
      color: #000;
    }
  }
  .dual-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > * {
      width: 48%;
    }
  }
  .MuiFormControl-root {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 16px;
    }
    .MuiInputLabel-root {
      color: #000;
      // font-size: rem(18);
      &.Mui-focused {
        // transform: translate(14px, 3px) scale(0.75);
      }
      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.6);
        cursor: unset;
        opacity: 0.5;
        // transform: translate(14px, 3px) scale(0.75);
      }
    }
    .MuiFilledInput-root {
      background-color: #fff;
      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.6);
        cursor: unset;
        opacity: 0.5;
        // transform: translate(14px, 3px) scale(0.75);
      }
    }
  }
}
.UserDashboard {
  background-image: url("../images/user-dash-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.UserActivity {
  &__container {
    margin-top: -2.5rem;
  }

  &__title {
    margin-bottom: -1rem;
  }
}

.filter-cont {
  background: #ffffff;
  padding: 18px 24px;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.auth-wrapper {
  padding: 14px 72px 72px 72px;
  max-width: 450px;
  margin: 100px auto 150px auto;
  .auth-title {
    font-size: rem(36);
    margin-bottom: 42px;
  }
  .auth-logo {
    max-width: 155px;
    padding: 20px;
    margin: 0 auto 20px auto;
    //background-color: #fff;
  }
  .auth-inputs {
    .MuiFormControl-root {
      margin-bottom: 16px;
      .MuiInputBase-root {
        background-color: #fff;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
      }
    }
  }
  .submit-buttons {
    max-width: 320px;
    margin: 24px auto;
    button {
      font-size: rem(18);
      padding: 15px 20px;
    }
  }
}
.filters {
  .filter-block {
    margin-bottom: 32px;
    .title {
      margin-bottom: 2rem;
    }
  }
}

.table-view {
  padding-top: 66px;
  padding-bottom: 160px;
  max-width: 1670px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
}
.table-title-search {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-bottom: 1.5rem;
  @media (max-width: 767px) {
    margin-top: 2rem;
  }
  .title {
  }

  .search {
    .SearchOption {
      margin-top: -1.5rem;
      .MuiFormGroup-root {
        .MuiFormControl-root {
          .MuiOutlinedInput-root {
            border-radius: 0;
            .MuiOutlinedInput-input {
            }

            .MuiOutlinedInput-notchedOutline {
              .css-hdw1oc {
                .notranslate {
                }
              }
            }
          }
        }

        .MuiButton-root {
          border-radius: 0;
          .MuiTouchRipple-root {
          }
        }
      }
    }
  }
}
.GenericTables {
  .MuiPaper-root {
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
          }
        }
      }
    }
  }
}

.dashboard_link_tree {
  background-color: #ededf0;
  padding: 32px;
}

.form-top {
  background: #f5f5f5;
  padding: 12px 0;
}

.selected {
  color: "#fff";
  background: "#0B4DA2";
  border-radius: 4px;
}

.Calculator {
  // max-width: 1440px;
  // display: flex;
  // align-items: stretch;
  // justify-content: flex-start;
  // margin: 0 auto;
  // .left {
  //   width: 350px;
  //   // min-width: 350px;
  // }
  // .right {
  //   flex-grow: 1;
  // }
}

.CalculatorSideBar {
  padding: 60px 32px;

  // width: 24.5%;
  .head {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .logo {
      img {
        height: 20px;
        margin-right: 16px;
      }
    }
    span {
      color: #5d7285;
    }
  }
  .sidebar-body {
    .user-type-list {
      background-color: #e9f5fe;
      padding: 20px;
      > p {
        font-weight: 600;
        color: $primaryColor;
      }
    }
    .calc-steps-list {
      margin-top: 20px;
      button {
        text-transform: capitalize;
        padding: 8px 16px;
        text-align: left;
        justify-content: flex-start;
        color: #000;
        font-weight: 600;
        &.selected {
          background-color: #e9f5fe;
          color: $primaryColor;
        }
      }
    }
  }
}
.StepsInputOptions {
  background-color: #5c96e0;
  padding: 60px 32px;
  // width: 380px;
  // width: 26.5%;

  .title {
    color: #ffffff;
    font-size: rem(34);
    font-weight: 400;
    margin-bottom: 32px;
  }

  .steps-body {
    // height: 600px;
    // border: 1px red solid;
    // overflow:  scroll;
  }
  .steps-nav {
  }
}

.calc-helper-text {
  font-size: 14px;
  &.black {
    color: #000;
  }
}
.CalcResultsView {
  padding: 18px;
  // width: 51%;
  flex: 0 0 52%;
}
.MuiAlert-root {
  font-weight: bold !important;
  &.MuiAlert-standardError {
    background-color: #ffd9d9;
  }
  &.MuiAlert-standardInfo {
    background-color: #c8e1ff;
  }
}
.TabsAndTables {
  .MuiTabPanel-root {
    padding: 0;
  }
}

.CalcDataTable {
  margin-bottom: 30px;
}

.CalculatorBars {
  margin-bottom: 32px;
  padding: 32px 0;
  width: 100%;
  // background-color: #fff;
  // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // border-radius: 4px;
  // box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  // margin-bottom: 30px;
  // .title {
  //   background-color: #5c96e0;
  //   font-weight: 500;
  //   font-size: 0.875rem;
  //   color: #fff;
  //   border-bottom: 1px solid rgba(224, 224, 224, 1);
  //   text-align: left;
  //   padding: 16px;

  //   border-top-right-radius: 4px;
  //   border-top-left-radius: 4px;
  // }

  // .bars-body {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: flex-end;
  //   padding: 16px;
  //   padding-top: 64px;
  //   .bar-list {
  //     width: 70%;
  //     display: flex;
  //     margin-top: 16px;
  //     .bar-contianer {
  //       text-align: center;
  //       display: flex;
  //       flex-direction: row;
  //       flex-wrap: wrap;
  //       align-items: flex-end;
  //       height: 150px;
  //       width: 65px;
  //       margin: 0 2%;
  //       align-content: flex-end;
  //       span {
  //         color: #828282;
  //         width: 100%;
  //         font-size: 12px;
  //         align-self: baseline;
  //         padding: 8px;
  //         &.value {
  //         }

  //         &.labelKey {
  //         }
  //       }

  //       .inner-bar {
  //         position: relative;
  //         border-top-right-radius: 8px;
  //         border-top-left-radius: 8px;
  //         span {
  //           &.value {
  //             top: -20px;
  //           }
  //           &.labelKey {
  //             bottom: -20px;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   .bar-label-list-container {
  //     // position: relativase;
  //     // min-height: 100%;
  //     // flex: 1 1 50%;
  //     flex-basis: 200px;
  //   }

  //   .bar-label-list {
  //     width: 100%;
  //     // position: absolute;
  //     // right: 0;
  //     bottom: 16px;
  //     padding-left: 32px;
  //     // display: flex;
  //     // flex-wrap: wrap;
  //     // justify-content: flex-end;
  //     // align-items: flex-end;
  //     // height: 80px;
  //     .bar-label-contianer {
  //       // width: 50%;
  //       // height: 26px;
  //       display: flex;
  //       align-items: flex-end;
  //       margin-bottom: 8px;
  //       .key {
  //         color: #fff;
  //         width: 26px;
  //         height: 26px;
  //         margin-right: 12px;
  //         text-align: center;
  //         border-radius: 4px;
  //         font-size: 14px;
  //         padding-top: 1px;
  //       }
  //       .label {
  //         color: #828282;
  //         // font-weight: 600;
  //         font-size: 14px;
  //       }
  //     }
  //   }
  // }
}

.result-action-buttons {
  max-width: 500px;
  margin: 32px auto;
  display: flex;
  justify-content: space-between;

  button {
    width: 45%;
    margin-bottom: 16px;
  }
}

.block-level-1 {
  border: 2px solid #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 6px;
  .block-title {
    color: #fff;
    margin-bottom: 16px;
    font-size: rem(20);
  }
}

#PdfView {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-self: start;
  height: 0;
  overflow: hidden;

  .MuiTableContainer-root {
    overflow: hidden;
  }

  .TotalCostOfOwnership,
  .WeightAndVolume {
    // border: 1px solid black;
  }
}

.pdf-logo {
  width: 1100px;
  height: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid black;
  img {
    width: 100%;
    height: auto;
  }
}

.CapacityDimensioning {
  .MuiCollapse-root {
    background-color: $primaryLightColor;
  }
}
.custom-row {
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  // justify-content: space-between;
  > div {
    min-width: 50%;
  }
  h2 {
    margin-bottom: 4px;
    margin-top: 4px;
  }
}

.footer-logo {
  float: left;
  img {
    max-width: 260px;
  }
  a {
    display: block;
    ;
    span {
      color: #fff;
      display: block;
      text-align: left;
      
      margin-bottom: 16px;
    }
  }
}
