.hero {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  @media (max-width: 767px) {
    height: 60vh;
  }

  &__banner {
    //position: relative;
  }

  &__videoContainer {
    position: absolute;
    top: -15rem;
    left: 0;
    width: 100%;
    @media (max-width: 767px) {
      top: -35rem;
    }
  }

  &__videoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.3;
  }

  &__videoDiv {
    transform: translate(0%, 0%);
    //z-index: -1;
  }

  &__title {
    position: absolute;
    top: 10rem;
    left: 0;
    right: 0;
    z-index: 2;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  &__titleCLip {
    background-image: linear-gradient(
      260deg,
      #00547e 0%,
      #ffffff 5%,
      #b4f9f5 12%
    );
    color: transparent;
    filter: drop-shadow(6px 6px 31px rgba(0, 0, 0, 0.62));
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 18px 23px rgba(0, 0, 0, 0.1);
    grid-area: overlap;
    letter-spacing: 1px;

    -webkit-text-stroke: 1px transparent;
    -webkit-background-clip: text;
  }

  &__headTitle {
    color: $white;
    font-size: 48px;
  }

  &__subTitle {
    font-weight: normal;
    color: $white;
  }

  //  &__bannerImg {
  //    width: 100%;
  //    height: 30rem;
  //    object-fit: cover;
  //  }
}

:root {
  --video-width: 100vw;
  --video-height: 100vh;
}
@media (min-aspect-ratio: 16/9) {
  :root {
    --video-height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  :root {
    --video-width: 177.78vh;
  }
}

.video-background {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--video-width);
  height: var(--video-height);
  transform: translate(-50%, -50%);
}

.ytplayer {
  pointer-events: none;
}
