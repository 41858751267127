//about intro

.aboutIntro {
  padding: 5rem 0 0 0;
}

//Core team

.coreTeam {
  &__teamMember img {
    margin-bottom: 1.5rem;
  }
}

//Time line

.timeLine {
  padding: 5rem 0 0 0;
  &__title {
    margin-bottom: 3rem;
  }

  &__img {
    height: 25rem;
  }

  &__cardYear {
    font-size: 48px;
    color: rgb(183, 182, 182);
  }

  &__cardTitle {
    font-size: 18px;
  }

  &__externalLink {
    text-decoration: none;
  }
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 50px;
  bottom: 250px;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #777;
  left: 50%;
  margin-left: -1.5px;
}

@media (max-width: 767px) {
  .timeline:before {
    bottom: 325px;
  }
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel-left {
  width: 45%;
  float: right;
  //  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  //  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  //  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel-right {
  width: 45%;
  float: right;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  text-align: right;
}

//.timeline > li > .timeline-panel:before {
//  position: absolute;
//  top: 26px;
//  right: -15px;
//  display: inline-block;
//  border-top: 15px solid transparent;
//  border-left: 15px solid #ccc;
//  border-right: 0 solid #ccc;
//  border-bottom: 15px solid transparent;
//  content: " ";
//}

//.timeline > li > .timeline-panel:after {
//  position: absolute;
//  top: 27px;
//  right: -14px;
//  display: inline-block;
//  border-top: 14px solid transparent;
//  border-left: 14px solid #fff;
//  border-right: 0 solid #fff;
//  border-bottom: 14px solid transparent;
//  content: " ";
//}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 30px;
  height: 30px;
  /* line-height: 50px; */
  /* font-size: 1.4em; */
  /* text-align: center; */
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -15px;
  background-color: #ffffff;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border: 6px solid #3b577c;
}

.timeline > li.timeline-inverted > .timeline-panel-right {
  float: left;
}

.timeline > li.timeline-inverted > .timeline-panel-left {
  float: none;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

//concept component

.conceptContent {
  &__fluid {
    background-image: url("../../../images/whyh2ships.jpg");
    background-size: cover;
    background-attachment: fixed;
    padding: 10rem 0 5rem 0;
  }

  &__why {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      display: block;
    }
  }

  &__whyTitle {
    background-color: $white;
    border-radius: 6px;
    margin-right: 2rem;
    padding: 1.5rem 3rem;
    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  &__whyDesc {
    background-image: linear-gradient(
      260deg,
      #00547e 0%,
      #ffffff 5%,
      #b4f9f5 12%
    );
    color: transparent;
    filter: drop-shadow(6px 6px 31px rgba(0, 0, 0, 0.451));
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.224),
      0px 8px 13px rgba(0, 0, 0, 10), 0px 18px 23px rgba(0, 0, 0, 10);
    grid-area: overlap;
    letter-spacing: 1px;
    -webkit-text-stroke: 1px transparent;
    -webkit-background-clip: text;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  &__list {
    background-color: $primaryLightColor;
    padding: 2.5rem;
    margin-top: 3rem;

    h2 {
      margin-bottom: 3rem;
      color: $white;
    }

    .col-12 {
      margin-bottom: 0.5rem;
      text-align: center;
      background-color: $white;
      color: $fontcolor1;
      font-size: 16px;
      padding: 2.5rem;
      margin-right: 0.5rem;
      border-radius: 6px;
      @media (max-width: 767px) {
        margin-right: 0;
        padding: 1rem;
      }
    }

    img {
      width: auto;
      height: 90px;
      margin-bottom: 2rem;
      @media (max-width: 767px) {
        height: 40px;
        margin-bottom: 0.5rem;
      }
    }

    p {
      @media (max-width: 767px) {
        margin-top: 1.5rem;
        font-size: 14px;
      }
    }
  }

  &__listRow {
    justify-content: center;
    @media (max-width: 767px) {
      display: block;
    }
  }

  &__img {
    position: relative;

    img {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__imgBox {
    position: absolute;
    top: 2rem;
    left: 2.5rem;
    border: 2.5px solid $primaryLightColor;
    border-radius: 0.25rem;
    width: 340px;
    height: 240px;
    z-index: -1;
    @media (max-width: 767px) {
      width: 190px;
      height: 160px;
    }
  }

  &__solution {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;

    @media (max-width: 767px) {
      display: block;
    }

    h2 {
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }

  &__solutionText {
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
    }

    p {
      font-size: 16px;
      margin-bottom: 2rem;
    }
  }
}

//pilot component

.pilotContent {
  padding: 8rem;

  &__intro {
    margin-bottom: 5rem;
    @media (max-width: 767px) {
      margin-bottom: 3rem;
    }

    h2 {
      color: $primaryLightColor;
      @media (max-width: 767px) {
        font-size: 28px;
      }
    }

    p {
      width: 80%;
      margin: 0 auto;
      @media (max-width: 767px) {
        font-size: 16px;
        //width: 100%;
      }
    }
  }

  &__pilotOne {
    h2 {
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
    p {
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  &__midText {
    p {
      @media (max-width: 767px) {
        margin: 2rem 0 10rem 0;
        font-size: 16px;
      }
    }
  }

  &__pilotTwo {
    h2 {
      margin-top: 1.5rem;
      @media (max-width: 767px) {
        margin-top: 4rem;
        font-size: 24px;
      }
    }
    p {
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}

//Meet the team style

.meetTeam {
  padding: 5rem 0 0 0;
  @media (max-width: 767px) {
    padding: 2rem 0 0 0;
  }

  &__title {
    font-size: 42px;
    text-align: center;
    margin-bottom: 4rem;
    @media (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 2rem;
    }
  }

  &__detailsTitle {
    font-size: 24px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  &__subText {
    font-size: 18px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__img {
    width: 45rem;
    height: 40rem;
    padding: 1rem 1rem;
    @media (max-width: 767px) {
      padding: 0;
    }
  }

  &__text {
    margin-top: 3rem;
    margin-left: 1.5rem;
  }
}
