.portHeader {
  padding: 4rem 0 0 0;

  &__title {
    font-size: 48px;
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  &__author {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1.5rem;
  }

  &__authorImg {
    margin-right: 1rem;
  }

  &__authorInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__authorDate {
    margin-top: -0.5em;
  }

  &__coverImgTitle {
    margin-top: 1.5rem;
  }
}

.portBody {
  padding: 2.5rem 0 5rem 0;
}
