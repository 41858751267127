.newsCard {
  padding: 3rem 0 6rem 0;

  &__col {
    margin-bottom: 2rem;

    @media (max-width: 767px) {
      margin-bottom: 3rem;
    }

    :hover {
      cursor: pointer;
    }
  }

  &__col:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  &__title {
    font-size: 42px;
    margin-bottom: 3rem;
  }

  &__card {
    //display: flex;
    //flex-direction: row;
    //justify-content: space-around;
    //align-content: flex-start;
    align-self: stretch;
    height: 100%;
    padding: 10px;
    border: none !important;
    @media (max-width: 767px) {
      display: block;
    }
  }

  &__cardBody {
    padding: 0px 0px;
    position: relative;
    padding-bottom: 40px;
    @media (max-width: 767px) {
      padding: 0px 0px;
      text-align: right;
    }
  }

  &__cardShare {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 2rem;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  &__btn {
    padding: 5px 10px;
    font-size: 14px;
  }

  &__imgWrapper {
    overflow: hidden;
    margin: 0 0 10px 0;
    /* -- Prevent flicker -- */
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    @media (max-width: 767px) {
      width: auto;
      //  height: 200px;
      margin: 0 0 20px 0;
    }
  }

  &__img {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    //overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 10rem;
  }

  &__link {
    text-decoration: none;
    color: $black;
  }

  &__cardNewsLetter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 4rem 0 0 0;
    &__title {
      margin-bottom: 1rem;
    }
  }

  &__img {
    border-radius: 0.25rem;
  }

  &__cardTitle {
    text-align: left;
    font-size: 16px;
    margin-bottom: 2rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__authorName {
    font-size: 14px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__feedCol {
    height: 67rem;
    overflow-y: scroll;
    @media (max-width: 767px) {
      margin-top: 3rem !important;
    }
  }
}

//news details

.newsHeader {
  padding: 4rem 0 4rem 0;

  &__title {
    font-size: 48px;
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  &__author {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 1.5rem;
    @media (max-width: 767px) {
      display: block;
      margin-top: 0;
    }
  }

  &__authorImg {
    margin-right: 1rem;
  }

  &__authorInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0 0 0;
    @media (max-width: 767px) {
      justify-content: flex-start;
      padding: 2rem 0 2rem 0;
    }
  }

  &__authorInfoText {
    flex-direction: column;
  }

  &__authorDate {
    margin-top: -0.5em;
  }

  &__coverImgTitle {
    margin-top: 1.5rem;
  }

  &__newsHeaderTop {
    padding: 0 0 2rem 0;
  }
}

//News share

.newsShare {
  width: 100%;
  //  padding: 3rem;
  &__flex {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 767px) {
      justify-content: flex-start;
      align-items: baseline;
    }
  }

  &__btnCard {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 9px;
    padding: 0 0.5rem;

    &:nth-of-type(1) {
      margin: 0 0.5rem 0 0;
    }

    &:nth-of-type(2) {
      margin: 0 0.5rem 0 0;
    }

    @media (max-width: 767px) {
      padding: 0.5rem 1rem;
      //  margin: 0 1rem;
    }

    &:hover {
      cursor: pointer;
    }

    a span {
      font-size: 12px;
    }

    a svg {
      //  margin-right: 0.3rem;
    }

    @media (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
  }

  &__twitter {
    background-color: #1c9bef;
    transition: all 0.3s;

    svg {
      color: $white;
    }
    span {
      color: $white;
    }

    &:hover {
      background-color: #0d79c1;
    }
  }

  &__linkedin {
    background-color: #0b65c2;
    transition: all 0.3s;

    svg {
      color: $white;
    }
    span {
      color: $white;
    }

    &:hover {
      background-color: #20558a;
    }
  }

  &__email {
    background-color: #df6e78;
    transition: all 0.3s;

    svg {
      color: $white;
    }
    span {
      color: $white;
    }

    &:hover {
      background-color: #c13945;
    }
  }
}
