.faqBody {
  padding: 5rem 0 5rem 0;
}

.faqAccordion {
  &__title {
    @media (max-width: 767px) {
      margin-top: 30px !important;
    }
  }
}
