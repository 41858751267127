.footer {
  padding: 2rem 0;
  background-color: #2e3c4f;
  text-align: center;
  color: $white;
  font-size: 14px;

  a {
    text-decoration: none;
  }

  &__colTitle {
    text-align: left;
  }

  &__underline {
    position: relative;
    padding-bottom: 0.5rem;
    display: block;
    &::after {
      position: absolute;
      bottom: 0px;
      content: " ";
      width: 30%;
      background: $white;
      height: 1px;
      left: 0%;
      right: 0%;
    }
  }

  &__col {
    @media (max-width: 767px) {
      margin-bottom: 3rem;
    }
  }

  &__col p {
    text-align: left;
  }

  &__colOne {
    text-align: left;
  }

  &__copyrightText {
    margin-top: 3rem;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
