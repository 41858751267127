//login page

.auth {
  background-image: url("../../../images/regbg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
  //  background: rgba(255, 255, 255, 0.091);
  //  -webkit-box-shadow: 0px 2px 9px 0 rgba(31, 38, 135, 0.096);
  //  box-shadow: 0px 2px 9px 0 rgba(31, 38, 135, 0.27);
  padding: 10px 50px 20px 50px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0.75rem;

  &__forgotLabel span {
    display: block;
    text-align: center;
    text-decoration: none !important;
  }

  &:hover {
    cursor: pointer;
  }
}
