/*helper classes*/

.full-height {
    height: 100vh;
}

.half-height {
    height: 50vh;
}

.full-width {
    height: 100vw;
}

.full-width-p {
    height: 100%;
}

.half-width {
    height: 50vw;
}

@mixin make-paddings($class, $padding) {
    .p-#{$class}-#{$padding} {
        padding: $padding * 1px !important;
    }

    .pl-#{$class}-#{$padding} {
        padding-left: $padding * 1px !important;
    }

    .pr-#{$class}-#{$padding} {
        padding-right: $padding * 1px !important;
    }

    .pt-#{$class}-#{$padding} {
        padding-top: $padding * 1px !important;
    }

    .pb-#{$class}-#{$padding} {
        padding-bottom: $padding * 1px !important;
    }
}

@mixin make-margins($class, $margin) {
    .m-#{$class}-#{$margin} {
        margin: $margin * 1px !important;
    }

    .ml-#{$class}-#{$margin} {
        margin-left: $margin * 1px !important;
    }

    .mr-#{$class}-#{$margin} {
        margin-right: $margin * 1px !important;
    }

    .mt-#{$class}-#{$margin} {
        margin-top: $margin * 1px !important;
    }

    .mb-#{$class}-#{$margin} {
        margin-bottom: $margin * 1px !important;
    }
}

@mixin make-all-paddings($class) {
    @for $i from 0 through 140 {
        @if $i % 4==0 {
            @include make-paddings($class, $i);
        }
    }

    @for $i from 0 through 140 {
        @if $i % 5==0 {
            @include make-paddings($class, $i);
        }
    }
}

@mixin make-all-margins($class) {
    @for $i from 0 through 140 {
        @if $i % 4==0 {
            @include make-margins($class, $i);
        }
    }

    @for $i from 0 through 140 {
        @if $i % 5==0 {
            @include make-margins($class, $i);
        }
    }
}

@media (max-width: 991px) {
    @include make-all-paddings(lg);
    @include make-all-margins(lg);
}

@media (max-width: 768px) {
    @include make-all-paddings(md);
    @include make-all-margins(md);
}


@media (max-width: 575px) {
    @include make-all-paddings(sm);
    @include make-all-margins(sm);
}


@include make-all-paddings(xs);
@include make-all-margins(xs);

@for $i from 1 through 141 {
    .#{mb}-#{$i} {
        margin-bottom: $i+px
    }
}
@media (max-width: 575px) {
    .text-center-sm {
        text-align: center !important;
    }

    .text-right-sm {
        text-align: right !important;
    }

    .text-left-sm {
        text-align: left !important;
    }
}

@media (max-width: 768px) {
    .text-center-md {
        text-align: center !important;
    }

    .text-right-md {
        text-align: right !important;
    }

    .text-left-md {
        text-align: left !important;
    }
}

@media (max-width: 991px) {
    .text-center-lg {
        text-align: center !important;
    }

    .text-right-lg {
        text-align: right !important;
    }

    .text-left-lg {
        text-align: left !important;
    }
}

@media(max-width: 575px){
    .c-pb-sm-0{
        padding-bottom: 0 !important;
    }
    .c-pt-sm-0{
        padding-top: 0 !important;
    }
}