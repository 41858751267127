.primaryColor-color {
  color: $primaryColor !important;
}

.primaryColor-bg {
  background-color: $primaryColor;
}

.white-color {
  color: $white;
}
.black-color {
  color: $black;
}

.white-bg {
  background-color: $white;
}

.success-color {
  color: $success;
}
.error-color {
  color: $error;
}
.errorDark-color {
  color: $errorDark;
}

.errorDark-bg {
  background-color: $errorDark;
}

body,
html {
  max-width: 100%;
  font-size: $baseFontSize + px;

  @media (max-width: 1600px) {
    font-size: $baseFontSize + px;
  }

  @media (max-width: 1200px) {
    font-size: $baseFontSize - 2 + px;
  }

  @media (max-width: 1024px) {
    font-size: $baseFontSize - 3 + px;
  }

  @media (max-width: 767px) {
    font-size: $baseFontSize - 4 + px;
  }
}

body {
  padding: 0;
  margin: 0;
  background: #ffffff;
  color: $bodycolor;
  font-family: $bodyfont;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: rem(16);
  line-height: 1.5;
  font-weight: 400;
  overflow-x: hidden !important;
  overflow-y: scroll;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #FAFAFA;
  &.newhome {
  }
  &:after {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.6);
    content: "";
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 99;
  }
  &.overlay:after {
    visibility: visible;
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1178px;
  }
}

.button:focus,
a,
button:focus,
img,
input:focus,
p a,
select:focus,
textarea:focus {
  //   outline: 0;
}

.covid-test-form .Mui-focused fieldset {
  border-color: $primaryColor !important;
}
*:focus {
  outline-style: none !important;
  box-shadow: none !important;
  /* border-color:transparent!important;*/
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis {
  overflow: hidden;
  width: 98%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Typography
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
  font-weight: 700;
  color: inherit;
  margin: 0;
}

.h1,
h1 {
  font-size: rem(44);
}

.h2,
h2 {
  font-size: rem(36);
}

.h3,
h3 {
  font-size: rem(28);
}

.h4,
h4 {
  font-size: rem(24);
}

.h5,
h5 {
  font-size: rem(20);
  @media (max-width: 1280px) {
    font-size: rem(18);
  }
}

.h6,
h6 {
  font-size: rem(16);
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.color-w {
  color: #ffffff;
}

.color-b {
  color: #000000 !important;
}
.color-dark {
  color: #4c4c4c !important;
}

.sml {
  font-size: rem(12);
}

.fz10 {
  font-size: rem(10);
}

.fz11 {
  font-size: rem(11);
}

.fz12 {
  font-size: rem(12);
}

.fz13 {
  font-size: rem(13);
}

.fz14 {
  font-size: rem(14);
}

.fz15 {
  font-size: rem(15);
}

.fz16 {
  font-size: rem(16);
}

.fz18 {
  font-size: rem(18);
  @media (max-width: 767px) {
    font-size: rem(16) !important;
  }
}

.fz19 {
  font-size: rem(19);
}

.fz20 {
  font-size: rem(20);
  line-height: 25px;
}

.fz22 {
  font-size: rem(22);
}

.fz24 {
  font-size: rem(24) !important;
  @media (max-width: 767px) {
    font-size: rem(18) !important;
  }
}

.fz28 {
  font-size: rem(28) !important;
  @media (max-width: 767px) {
    font-size: rem(24) !important;
  }
}

.text-underline {
  text-decoration: underline;
}

.cursor-p {
  cursor: pointer;
}

.bg-gray {
  background-color: #fafafa;
}
.bg-transparent {
  background-color: transparent;
}

.bg-secondary {
  background-color: $orangeNew !important;
}

a {
  color: $primaryColor;
  text-decoration: none;
  transition: color 300ms ease-in-out;
  &:hover {
    color: $primaryDarkColor;
    text-decoration: none;
  }
}

p {
  margin: 0 0 10px;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

ul {
  list-style: none;
}

ol {
  list-style: decimal inside;
}

.st-ul-list {
  list-style: disc;
  padding-left: 16px;
  margin-bottom: 16px;
  margin-top: 16px;

  li {
    margin-bottom: 8px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

input {
  &:active,
  &:focus {
    border-color: $primaryColor !important;
  }
}

.swal-title {
  font-size: rem(16);
}

.text-info {
  color: $color1 !important;
}

.text-danger {
  color: $color2 !important;
}

.text-gray {
  color: #4c4c4c !important;
}

.text-light-gray {
  color: #8e8e93;
}

.text-success {
  color: #43a046 !important;
}
.text-caution {
  color: #f9a825 !important;
}

.text-orange {
  color: #ef7e46;
}

.text-primary {
  color: $primaryColor;
}
.text-primaryDark {
  color: $primaryDarkColor;
}

.text-secondary {
  color: $orangeNew !important;
}
.rdatez {
  display: block;
}

.radius-4 {
  border-radius: 4px !important;
  overflow: hidden;
}

.radius-8 {
  border-radius: 8px;
  overflow: hidden;
}

//added by hasan
//misc > pagination

.btn-primary {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

.btn-outline-primary {
  color: $primaryColor !important;
  border-color: $primaryColor !important;

  &:hover{
	  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
  color: $white !important;
  }
}

.pagination {
  padding: 1rem 0 0rem 0;
}

.page-item.active .page-link {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

a {
  text-decoration: none !important;
}
