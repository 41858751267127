
// Margin and Padding
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
), $spacers);

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
  }

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
}
  
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      @each $prop, $abbrev in (margin: c_m, padding: c_p) {
        @each $size, $length in $spacers {
  
          .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
          .#{$abbrev}t#{$infix}-#{$size},
          .#{$abbrev}y#{$infix}-#{$size} {
            #{$prop}-top: $length !important;
          }
          .#{$abbrev}r#{$infix}-#{$size},
          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-right: $length !important;
          }
          .#{$abbrev}b#{$infix}-#{$size},
          .#{$abbrev}y#{$infix}-#{$size} {
            #{$prop}-bottom: $length !important;
          }
          .#{$abbrev}l#{$infix}-#{$size},
          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-left: $length !important; 
          }
        }
      }
  
      // Some special margin utils
      .m#{$infix}-auto { margin: auto !important; }
      .mt#{$infix}-auto,
      .my#{$infix}-auto {
        margin-top: auto !important;
      }
      .mr#{$infix}-auto,
      .mx#{$infix}-auto {
        margin-right: auto !important;
      }
      .mb#{$infix}-auto,
      .my#{$infix}-auto {
        margin-bottom: auto !important;
      }
      .ml#{$infix}-auto,
      .mx#{$infix}-auto {
        margin-left: auto !important; 
      }
    }
  }
  