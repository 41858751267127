.linksCard {
  padding: 3rem 0 6rem 0;

  &__col {
    margin-bottom: 2rem;
    @media (max-width: 767px) {
      margin-bottom: 3rem;
    }

    :hover {
      cursor: pointer;
    }
  }

  &__col:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  &__title {
    font-size: 42px;
    margin-bottom: 3rem;
  }

  &__card {
    //display: flex;
    //flex-direction: row;
    //justify-content: space-around;
    //align-content: flex-start;
    align-self: stretch;
    height: 100%;
    padding: 10px;
    border: none !important;
	background-color: #ececec;
	position: relative;
    @media (max-width: 767px) {
      display: block;
    }
  }

   &__chip{
	  position: absolute;
	  top: 7.4rem;
	  right: .5rem;
	  z-index: 1000;
	   @media (max-width: 767px) {
      		top: 6.5rem;
    }
  }

  &__cardBody {
    padding: 0px 0px;
    position: relative;
    padding: 10px 0 0 0;
    @media (max-width: 767px) {
      padding: 0px 0px;
    }
  }

  &__cardText {
	font-size: 14px;
  }

  &__fuelDiv{
	  display: flex;
	  flex-direction: row;
	  justify-content: flex-start;
	  align-items: baseline;

	  p:nth-of-type(1){
			margin-right: .5rem;		 
	}
  }

  &__btn{
		background-color: #3381E4;
		color: $black;
		//display: flex;
		//justify-content: flex-end;
		//align-items: flex-end;
		display: block;
		float: right;
		transition: all .3s;

		&:hover{
		background-color: #0B4DA2;
		}

		  @media (max-width: 767px) {
			margin-bottom: 1rem;
			}
	  }

//  &__cardText{
//	  :nth-of-type(5){
//			margin-right: 2.5rem;		 
//	}
//  }



  &__imgWrapper {
    
    overflow: hidden;
    margin: 0 0 10px 0;
	// margin: -16px;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    @media (max-width: 767px) {
      width: auto;
      //  height: 200px;
      margin: 0 0 20px 0;
    }
  }

 

  &__img {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    //overflow: hidden;
    object-fit: cover;
    height: 10rem;
    // width: auto;
    width: 100%;

    
  }

  &__link {
    text-decoration: none;
    color: $black;
  }

  &__cardNewsLetter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 4rem 0 0 0;
    &__title {
      margin-bottom: 1rem;
    }
  }

  &__img {
    border-radius: 0.25rem;
	
  }

}
