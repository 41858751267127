//top menu


//navbar
.navbar {
  padding: .5rem 8rem;
  background-color: $gray3;

  @media (max-width: 767px) {
    padding: 1rem 2rem;
  }

  &__logo {
    width: 8.5rem;
    margin-top: 1rem;

    span {
      display: block;
      color: $black;
      font-size: 12px;
      letter-spacing: -0.4px;
      margin-bottom: 5px;
    }
  }

  &__nav-link {
    color: $fontcolor1 !important;
    transition: all 0.5s ease-in;
    border-radius: 7px;
    &:hover {
      background: $gray5 !important;
      border-radius: 7px;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__dropdown-menu {
    background-color: $gray3 !important;
  }
}
