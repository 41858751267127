.subscribeForm {
  background-image: url("../../../images/signupBg.jpeg");
  background-size: cover;
  background-attachment: fixed;

  &__row {
    padding: 5rem 5rem 5rem 5rem;
    justify-content: center;
    @media (max-width: 767px) {
      padding: 5rem 2rem 5rem 2rem;
    }
  }

  &__title {
    margin-top: 3rem;
    @media (max-width: 767px) {
      text-align: center;
    }
  }

  &__title h4 {
    font-size: 42px;
    @media (max-width: 767px) {
      font-size: 28px;
    }
  }

  &__title h5 {
    font-weight: normal;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__formTitle {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
    @media (max-width: 767px) {
      font-size: 22px;
      margin-top: 3rem;
    }
  }

  &__formFloating {
    margin-bottom: 1.5rem;
  }

  &__submitBtn {
    background-color: $primaryColor !important;
    border-color: $primaryColor !important;
  }
}
